
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditStructureModal from "@/components/modals/referencials/structures/EditStructureModal.vue";

interface IPagination {
    page  : number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-structures",
    props: {
        widgetClasses: String,
    },
    components: {
        EditStructureModal,
    },
    setup() {
        const { t, te } = useI18n();

        let loading = ref(false);
        let activeStructure = ref(null);

        const structures = ref([]);
        const pagination = ref<IPagination>({
            page  : 1,
            total : 0,
            offset: 10,
        });

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const goPage = (page: number) => {
            pagination.value.page = page;

            getStructures();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getStructures();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getStructures();
        };

        const create = () => {
            activeStructure.value = null;
            const modal = new Modal(document.getElementById('kt_modal_edit_structure'));
            modal.show();
        };

        const edit = (structure) => {
            activeStructure.value = structure;
            const modal = new Modal(document.getElementById('kt_modal_edit_structure'));
            modal.show();
        };

        const remove = (structure, level) => {
            const code = structure[`structureL${level}Code`];

            if (code) {

                axios.delete(`${ApiRoutes.structures.delete}/${code}`, { data: { level: level } })
                    .then((response: AxiosResponse) => {

                        if (response.data.success) {

                            Swal.fire({
                                text: response.data.message,
                                icon: "success",
                                buttonsStyling: false,
                                confirmButtonText: translate("confirm"),
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                            }).then(() => {
                                refresh();
                            });
                        } else {

                            Swal.fire({
                                text: response.data.message,
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: translate("confirm"),
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                            });
                        }
                    })
                    .catch((error) => {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    });

            } else {

                Swal.fire({
                    text: translate("structureDoesNotExist"),
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: translate("confirm"),
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                });
            }
        };

        const confirmRemove = (structure, level) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("structureConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(structure, level);
                }
            });
        };

        const getStructures = async () => {
            loading.value = true;

            await axios.get(ApiRoutes.structures.index, { params: pagination.value })
                .then((response: AxiosResponse) => {
                    loading.value = false;

                    pagination.value.total = response.data.total;

                    structures.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });

        };

        onMounted(() => {
            getStructures();

            setCurrentPageBreadcrumbs(translate("structureHeading"), [translate("masterDataHeading"), translate("referencialsHeading")]);
        });

        return {
            translate,
            structures,
            pagination,
            activeStructure,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
