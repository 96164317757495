
import * as Yup from "yup";
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "edit-structure-modal",
    emit: ["refreshData"],
    props: {
        data: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        let isUpdate = false as boolean;
        let errors: any = ref();

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editStructureModalRef = ref<null | HTMLElement>(null);

        const structureL1Names = ref([]);
        const structureL2Names = ref([]);
        const structureL3Names = ref([]);
        const structureL4Names = ref([]);
        const structureL5Names = ref([]);

        const structureDefault = {
            structureL1Name: "",
            structureL1Description: "",
            structureL2Name: "",
            structureL2Description: "",
            structureL3Name: "",
            structureL3Description: "",
            structureL4Name: "",
            structureL4Description: "",
            structureL5Name: "",
            structureL5Description: "",
        };
        const structureData = ref<any>({});
        Object.assign(structureData.value, structureDefault);

        const validationSchema = Yup.object().shape({
            // structureL1Name: Yup.string().required().label(translate("structureL1NameLabel")),
            // structureL1Description: Yup.string().nullable().label(translate("structureL1DescriptionLabel")),
            // structureL2Name: Yup.string().nullable().label(translate("structureL2NameLabel")),
            // structureL2Description: Yup.string().nullable().label(translate("structureL2DescriptionLabel")),
            // structureL3Name: Yup.string().nullable().label(translate("structureL3NameLabel")),
            // structureL3Description: Yup.string().nullable().label(translate("structureL3DescriptionLabel")),
            // structureL4Name: Yup.string().nullable().label(translate("structureL4NameLabel")),
            // structureL4Description: Yup.string().nullable().label(translate("structureL4DescriptionLabel")),
            // structureL5Name: Yup.string().nullable().label(translate("structureL5NameLabel")),
            // structureL5Description: Yup.string().nullable().label(translate("structureL5DescriptionLabel")),
        });

        const submit = () => {
            errors.value = null;

            disableSubmitButton(submitButtonRef);

            axios.post(ApiRoutes.structures.updateOrCreate, structureData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            hideModal(editStructureModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        const getStructuresNames = async () => {
            let response = await axios.get(ApiRoutes.structures.names, { params: { level: 1 } });
            structureL1Names.value = response.data;

            response = await axios.get(ApiRoutes.structures.names, { params: { level: 2 } });
            structureL2Names.value = response.data;

            response = await axios.get(ApiRoutes.structures.names, { params: { level: 3 } });
            structureL3Names.value = response.data;

            response = await axios.get(ApiRoutes.structures.names, { params: { level: 4 } });
            structureL4Names.value = response.data;

            response = await axios.get(ApiRoutes.structures.names, { params: { level: 5 } });
            structureL5Names.value = response.data;
        };

        onMounted(() => {
            const modal = document.getElementById('kt_modal_edit_structure');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(structureData.value, structureDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;
                errors.value = null;

                if (props.data && structureData.value) {
                    isUpdate = true;

                    // Fill structure fields
                    Object.keys(structureData.value).forEach((key) => {
                        structureData.value[key] = props.data[key];
                    });
                }
            });

            getStructuresNames();
        });

        return {
            translate,

            submitButtonRef,
            editStructureModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            structureData,
            structureL1Names,
            structureL2Names,
            structureL3Names,
            structureL4Names,
            structureL5Names,

            submit,
        };
    },
});
